var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "white--text py-0 my-0 top-bar",
      attrs: { color: "#3F51B5", height: "56px", app: "" }
    },
    [
      _c(
        "v-app-bar-nav-icon",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.toggleMenu(!_vm.isMenuOpen)
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "d-flex justify-space-between align-center" }, [
        _c("div", { staticClass: "white--text" }, [
          _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }