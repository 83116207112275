<template>
  <v-app-bar
    color="#3F51B5"
    class="white--text py-0 my-0 top-bar"
    height="56px"
    app
  >
    <v-app-bar-nav-icon>
      <v-btn text color="white" @click="toggleMenu(!isMenuOpen)">
        <v-icon>{{ mdiMenu }}</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>

    <div class="d-flex justify-space-between align-center">
      <div class="white--text">
        <div>
          {{ title }}
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mdiMenu } from "@mdi/js";

export default {
  name: "SharedSitePlainTopBar",
  computed: {
    ...mapGetters(["isMenuOpen"]),
  },
  data() {
    return {
      mdiMenu,
      sharedSite: {},
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
  },
  props: {
    title: String,
  },
};
</script>
