var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c(
            "SharedSiteLayout",
            [
              _c("SharedSitePlainTopBar", {
                ref: "topBar",
                attrs: { title: "Sites" }
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center py-10 my-10" },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        "max-width": "600px",
                        "min-width": _vm.$vuetify.breakpoint.xsOnly
                          ? "100vw"
                          : "600px",
                        id: "sitesListCard"
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex gap" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "disable-pointer",
                              attrs: {
                                fab: "",
                                dark: "",
                                color: "#2193F6",
                                "x-small": "",
                                ripple: false,
                                elevation: "0"
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiGoogleMaps) + " ")
                              ])
                            ],
                            1
                          ),
                          _c("div", [_vm._v("Sites")])
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            staticClass:
                              "elevation-0 flex-grow-1 sites-table cursor-pointer",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.mappedSites,
                              "item-key": "compoundId",
                              "group-by": "user_group_id",
                              "fixed-header": "",
                              "hide-default-footer": "",
                              "disable-pagination": ""
                            },
                            on: { "click:row": _vm.onRowClick },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "group.header",
                                  fn: function(ref) {
                                    var isOpen = ref.isOpen
                                    var items = ref.items
                                    var toggle = ref.toggle
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "ma-0 pa-0 group-toggle group-header",
                                          attrs: { colspan: "9" },
                                          on: { click: toggle }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex ml-2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          isOpen
                                                            ? _vm.mdiChevronUp
                                                            : _vm.mdiChevronDown
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-2" },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getGroupHeading(
                                                          items
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }