<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0">
      <SharedSiteLayout>
        <SharedSitePlainTopBar title="Sites" ref="topBar" />

        <div class="d-flex justify-center py-10 my-10">
          <v-card
            max-width="600px"
            :min-width="$vuetify.breakpoint.xsOnly ? '100vw' : '600px'"
            id="sitesListCard"
          >
            <v-card-title class="d-flex gap">
              <v-btn
                fab
                dark
                color="#2193F6"
                x-small
                :ripple="false"
                elevation="0"
                class="disable-pointer"
              >
                <v-icon>
                  {{ mdiGoogleMaps }}
                </v-icon>
              </v-btn>
              <div>Sites</div>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="mappedSites"
                item-key="compoundId"
                class="elevation-0 flex-grow-1 sites-table cursor-pointer"
                group-by="user_group_id"
                fixed-header
                hide-default-footer
                @click:row="onRowClick"
                disable-pagination
              >
                <template v-slot:[`group.header`]="{ isOpen, items, toggle }">
                  <td
                    colspan="9"
                    class="ma-0 pa-0 group-toggle group-header"
                    @click="toggle"
                  >
                    <div class="d-flex ml-2">
                      <v-btn text x-small>
                        <v-icon>
                          {{ isOpen ? mdiChevronUp : mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                      <div class="ml-2">
                        <b>{{ getGroupHeading(items) }}</b>
                      </div>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </SharedSiteLayout>
    </v-main>
  </v-app>
</template>

<script>
import SharedSitePlainTopBar from "@/components/shared-site/SharedSitePlainTopBar";
import { mdiGoogleMaps, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import { axiosWithJwtAuth } from "@/plugins/axios";
import jwt_decode from "jwt-decode";
import SharedSiteLayout from "@/components/app/SharedSiteLayout.vue";
import sharedSiteUsetifulMixin from "@/mixins/sharedSiteUsetifulMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Site Name",
    align: "start",
    sortable: true,
    value: "site_name",
    width: "120px",
  },
  {
    text: "Address",
    align: "start",
    sortable: true,
    value: "address",
    width: "150px",
  },
];

export default {
  name: "Sites",
  components: {
    SharedSitePlainTopBar,
    SharedSiteLayout,
  },
  mixins: [sharedSiteUsetifulMixin],
  data() {
    return {
      mdiGoogleMaps,
      mdiChevronUp,
      mdiChevronDown,
      headers,
      sites: [],
    };
  },
  computed: {
    mappedSites() {
      return this.sites.map((s) => {
        const { site_contact_id: siteContactId, site_id: siteId } = s;
        return {
          compoundId: `${siteContactId}-${siteId}`,
          ...s,
        };
      });
    },
  },
  methods: {
    getGroupHeading(items) {
      return items?.[0]?.["user_group_name"];
    },
    async getSites() {
      try {
        const token = localStorage.getItem("shared-site-token");
        const decoded = jwt_decode(token);
        const { email_address: emailAddress } = decoded;
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site_contacts/all/${emailAddress}`
        );
        this.sites = results;
      } catch (error) {
        console.log(error);
      }
    },
    async onRowClick(row) {
      const { site_id: siteId } = row;
      const contact = localStorage.getItem("shared-site-token");
      this.$router.push({
        name: "SharedSite",
        query: {
          contact,
          siteId,
        },
      });
    },
  },
  mounted() {
    this.getSites();
  },
};
</script>

<style scoped>
.disable-pointer {
  pointer-events: none;
}

.gap {
  gap: 10px;
}

.sites-table >>> tr,
.sites-table >>> tr td {
  cursor: pointer;
}
</style>
